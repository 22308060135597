import React from "react";
import { gql } from "@apollo/client";

export const Query_benefitListCollection = gql`
  {
    benefitListCollection {
      items {
        benefit
      }
    }
  }
`;

export const Query_homeCollection = gql`
  {
    homeCollection {
      items {
        title
        subtitle {
          json
        }
        bannerCollection {
          items {
            url
          }
        }
        bannerMobile {
          url
        }
        fisrtBlock {
          json
        }
      }
    }
  }
`;

export const Query_categoryCollection = gql`
  {
    categoryCollection {
      items {
        name
        icon {
          url
        }
      }
    }
  }
`;

export const Query_creditCardCollection = gql`
  {
    creditCardCollection {
      items {
        name
        id
        creditCard {
          url
        }
      }
    }
  }
`;

export const Query_servicioConciergeDataCollectionn = gql`
  query servicioConciergeDataCollectionQuery($str: [String]) {
    servicioConciergeDataCollection(where: { benefitlist_contains_some: $str }) {
      items {
        name
        benefitlist
        icon {
          url
        }
        benefit {
          json
        }
        benefitlist
        image {
          url
        }
        buttonTxt {
          json
        }
        buttonLink {
          json
        }
        slidersCollection {
          items {
            url
          }
        }
      }
    }
  }
`;

export function buildCollectionQueryName(str) {
  let newStr = "";
  if (str === "FINE HOTEL & RESORTS") newStr = "hotelsResortsCollection";
  else if (str === "MEMBRESÍA SIXT") newStr = "membresaSixtCollection";
  else {
    newStr =
      str
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, "") + "Collection";
  }
  return newStr;
}

// export function buildQueryName(str) {
//   let newStr = "";
//   if (str === "FINE HOTEL & RESORTS") newStr = "hotelsResorts";
//   else if (str === "MEMBRESÍA SIXT") newStr = "membresaSixt";
//   else {
//     newStr = str
//       .toLowerCase()
//       .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
//         return word.toUpperCase();
//       })
//       .replace(/\s+/g, " ");
//   }
//   return newStr;
// }

export function lowerCaseFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}
