import { navigate } from "gatsby-link";
import React from "react";

import Americanexpresswhite from "../assets/images/americanexpresswhite.png";

import Bancoguayaquilwhite from "../assets/images/bancoguayaquilwhite.png";

function Header() {
  return (
    <div className="header_main">
      <div className="header_wrap">
        <div className="header_left">
          <div className="americanexpress">
            <img
              className="americanexpresswhite cursor-pointer "
              onClick={() => navigate("/")}
              src={Americanexpresswhite}
              alt="Americanexpresswhite"
            />
          </div>
          <div className="live_life_text">
            <h4>
              DON'T <span>live life</span> WITHOUT IT
            </h4>
          </div>
        </div>
        <div className="header_right">
          <a href="https://www.bancoguayaquil.com/" target="_blank"><img className="bancoguayaquilwhite" src={Bancoguayaquilwhite} alt="Bancoguayaquilwhite" /></a>
        </div>
      </div>
      <div className="menu_header">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a className="nav-link text-white" href="/">
                  Inicio
                </a>
              </li>
              {/*<li className="nav-item">
                <a className="nav-link text-white" href="#">
                  Test
                </a>
              </li>*/}
         
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
