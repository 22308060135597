import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

function RichTextEditor({ text, className, PIsbold = false, pFontFamily, ...rest }) {
  const Bold = ({ children }) => <b style={{ fontFamily: "bentonsans_boldregular" }}>{children}</b>;

  const Text = ({ children }) => (
    <p style={{ fontFamily: pFontFamily ? pFontFamily : "bentonsanslight" }}>{children}</p>
  );

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
    renderText: (text) => text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  };

  return (
    <div {...rest} className={className + " " + "richTextEdior"}>
      {text && Object.keys(text).length && documentToReactComponents(text, options)}
    </div>
  );
}

export default RichTextEditor;
