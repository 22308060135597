import React from 'react'
import Slider from 'react-slick'

export default function Carousel({ children, settings, isLeft }) {
  const filteredChildren = children.filter((item) =>
    Array.isArray(item) ? item[0] : item
  )
  const defaultSettings = {
    ...settings,
    infinite: filteredChildren.length > settings.slidesToShow - 1,
    ...(isLeft && { className: 'center-slick-track' }),
  }
  return <Slider {...defaultSettings}>{children}</Slider>
}
