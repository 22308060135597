import React from "react";

import Americanexpress from "../assets/images/americanexpress.png";
import Bancoguayaquilwhite from "../assets/images/bancoguayaquilwhite.png";
import DonotText from "../assets/images/brand_text.png";

function Footer({ isBGGray = false }) {
  return (
   
    <div>
  
      <div className={`brand_title ${isBGGray ? "bg-gray" : ""}`}>
        <div className="brand_main">
          <img src={Americanexpress} alt="Americanexpress" />
        </div>
        <div className="donot_text">
          <img src={DonotText} alt="Americanexpress" />
        </div>
      </div>
      <div className="terminosgenerales bg-gray"><p>Esta página está destinada solo a los titulares de Tarjetas American Express emitidas por Banco Guayaquil. Las ofertas y beneficios están sujetos a términos y condiciones,  revise los términos establecidos en cada oferta individual para verificar su elegibilidad.</p>
      <p> American Express no se responsabiliza por los productos y/o servicios ofrecidos por los comercios participantes.  Se reserva el derecho de modificar o cancelar estas ofertas en cualquier momento. American Express es una marca de American Express®. Las Tarjetas American Express son emitidas por Banco Guayaquil bajo licencia de American Express.</p>
          </div>
      <div className="footer_wrap">
        <div className="footer_left">
          <div className="footer_right_img order-1 order-lg-2">
            <img src={Bancoguayaquilwhite} alt="Bancoguayaquilwhite" />
          </div>
        </div>
       
        <div className="footer_right">
          <button className="order-2 order-lg-1">
            <a
              href="https://ayuda.bancoguayaquil.com/hc/es?utm_source=beneficiosbancoguayaquil.com"
              target="_blank"
              className="text-decoration-none"
            >
              ¿Necesitas ayuda?
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Footer;
