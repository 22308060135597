import React from "react";

import "../assets/css/fonts.css";
import "../assets/css/variables.css";
import "../assets/css/global.css";

import Seo from "./SEO";
// import Footer from './footer'

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <>
        <Seo />
        <main>{children}</main>
        {/* <Footer /> */}
      </>
    );
  }
}

export default Layout;
